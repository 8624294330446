<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="650"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div icon v-bind="attrs" v-on="on" class="advanced-btn">
        <span> Advanced filter </span>
        <v-icon small>fal fa-angle-down</v-icon>
      </div>
    </template>

    <v-card>
      <div class="advanced-filters-title">
        Advanced filter
      </div>
      <div class="date-range-template advanced-filters">
        <div class="filter-title">
          Created Subscription
        </div>
        <date-range-picker
          @changePeriod="changePeriod('created_at', $event)"
          :label="false"
          :ranges="ranges"
          opens="right"
          :value="{
            start_date: filtersPicker.created_at_range_start,
            end_date: filtersPicker.created_at_range_end,
          }"
        >
        </date-range-picker>
      </div>
      <div class="date-range-template advanced-filters">
        <div class="filter-title">
          Cancelled subscription
        </div>
        <date-range-picker
          @changePeriod="changePeriod('canceled_at', $event)"
          :label="false"
          opens="right"

          :ranges="ranges"
          :value="{
            start_date: filtersPicker.canceled_at_range_start,
            end_date: filtersPicker.canceled_at_range_end,
          }"
        >
        </date-range-picker>
      </div>
      <div class="date-range-template advanced-filters">
        <div class="filter-title">
          Subscription end date
        </div>
        <date-range-picker
          @changePeriod="changePeriod('ends_at', $event)"
          :label="false"
          :ranges="ranges"
          opens="right"

          :value="{
            start_date: filtersPicker.ends_at_range_start,
            end_date: filtersPicker.ends_at_range_end,
          }"
        >
        </date-range-picker>
      </div>
      <div class="date-range-template advanced-filters">
        <div class="filter-title">
          Next payment period
        </div>
        <date-range-picker
          @changePeriod="changePeriod('next_period_at', $event)"
          :label="false"
          :ranges="ranges"
          opens="right"
          :value="{
            start_date: filtersPicker.next_period_at_range_start,
            end_date: filtersPicker.next_period_at_range_end,
          }"
        >
        </date-range-picker>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="menu = false">
          Clear
        </v-btn>
        <v-btn color="primary" @click="saveFilters">
          Filter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import DateRangePicker from "@/components/common/filters/DateRangePicker";

export default {
  components: {
    DateRangePicker,
  },
  props: {
    filters: {
      required: true,
      type: Object,
    },
  },
  data() {
    const today = new Date();
    const yesterday = this.$moment(today).subtract(1, "days");
    const last7days = this.$moment(today).subtract(6, "days");
    const last30days = this.$moment(today).subtract(29, "days");
    const lastWeekStart = this.$moment(today)
      .subtract(1, "week")
      .isoWeekday(1)
      .startOf("isoweek");
    const lastWeekEnd = this.$moment(today)
      .subtract(1, "week")
      .isoWeekday(1)
      .endOf("isoweek");
    const lastMonthStart = this.$moment(today)
      .subtract(1, "month")
      .startOf("month");
    const lastMonthEnd = this.$moment(today)
      .subtract(1, "month")
      .endOf("month");
    return {
      menu: false,
      filtersPicker: {},
      ranges: {
        BRUGERDEFINERET: [null, null],
        "Today": [today, today],
        "Yesterday": [yesterday._d, yesterday._d],
        "Last week": [lastWeekStart._d, lastWeekEnd._d],
        "Last month": [lastMonthStart._d, lastMonthEnd._d],
        "Last 7 days": [last7days._d, today],
        "Last 30 days": [last30days._d, today],
      },
    };
  },

  methods: {
    saveFilters() {
      this.menu = false;
      this.$emit("save", this.cloneDeep(this.filtersPicker));
    },
    changePeriod(name, event) {
      this.filtersPicker[`${name}_range_start`] = event.start_date;
      this.filtersPicker[`${name}_range_end`] = event.end_date;
      this.saveFilters();
    },
  },
  created() {
    this.filtersPicker = this.cloneDeep(this.filters);
  },
};
</script>
<style lang="scss">
.advanced-btn {
  margin-top: 4px;
  span {
    cursor: pointer;
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
  }
  .v-icon {
    color: #4f4f4f;
    font-weight: 500;
    font-size: 18px !important;
  }
}
.date-range-template.advanced-filters {
  margin-left: 0;
  width: 260px;
}
.advanced-filters-title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding: 22px;
  padding-bottom: 0;
}
.filter-title {
  font-size: 14px;
  color: #009688;
  margin-bottom: 6px;
}
</style>
