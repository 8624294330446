<template>
  <v-dialog v-model="showing" max-width="550px" content-class="create-wrap">
    <v-card>
      <v-card-title>
        <div class="title">
          Create subscription
        </div>
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step">
          <v-stepper-content step="1">
            <div class="description">Choose business</div>

            <business-autocomplete
              v-model="business"
              :return-object="true"
            ></business-autocomplete>
            <div
              v-if="business && !business.user.card_last_four"
              class="error--text mt-2"
            >
              This customer hasn't added card
            </div>
            <div class="text-right mt-15 mb-4">
              <v-btn
                color="primary"
                :disabled="
                  !business ||
                    (business && business.user.active_subscription)
                "
                medium
                @click="step = step + 1"
                >NEXT</v-btn
              >
              <div
                v-if="business && business.user.active_subscription"
                class="error--text mt-2"
              >
                Business already has subscription
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="description">Select product plan</div>
            <stripe-product-plan-select v-model="selectedPlan" clearable />

            <div class="d-flex action-btn mt-15 mb-4">
              <v-btn color="primary" text @click="step = step - 1" outlined
                >PREVIOUS</v-btn
              >
              <v-btn
                color="primary"
                :disabled="!selectedPlan"
                medium
                @click="step = step + 1"
                >NEXT</v-btn
              >
            </div>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div class="small-title mb-2">Trial</div>
            <span class="form-descr"
              >Trial provides the customer with free subscription time. A
              payment will only take place after the trial ends</span
            >
            <v-checkbox
              color="#013138"
              v-model="trial"
              label="Add trial"
            ></v-checkbox>

            <div class="input-form" v-if="trial">
              <div>How many trial days?</div>
              <div>
                <v-text-field
                  v-model.number="form.trial_period_days"
                  outlined
                  number
                  placeholder="Trial days"
                ></v-text-field>
              </div>
            </div>
            <div class="d-flex action-btn mt-15 mb-4">
              <v-btn color="primary" text @click="step = step - 1" outlined
                >PREVIOUS</v-btn
              >
              <v-btn color="primary" medium @click="step = step + 1"
                >NEXT</v-btn
              >
            </div>
          </v-stepper-content>
          <v-stepper-content step="4">
            <div class="small-title ">Collection method</div>
            <v-radio-group v-model="form.collection_method" column>
              <v-radio
                label="Automatically charge a payment  from the card"
                color="primary"
                :disabled="business && !business.user.card_last_four"
                value="charge_automatically"
              ></v-radio>
              <div
                v-if="business && !business.user.card_last_four"
                class="error--text mb-2"
              >
                This customer hasn't added card
              </div>

              <v-radio
                label="Email invoice to the customer to pay manually"
                color="primary"
                value="send_invoice"
              ></v-radio>
            </v-radio-group>
            <div class="input-form">
              <div class="small-title ">
                Tax
              </div>
              <v-select
                v-model="tax"
                :items="taxRates"
                :item-text="taxText"
                item-value="stripe_tax_rate_id"
                clearable
                multiple
                return-object
                class="mr-10 filter-select width"
              >
              </v-select>
            </div>
            <div class="input-form" v-if="form.collection_method ==='send_invoice'">
              <div class="small-title mt-2">
                Days until invoice due
              </div>
              <v-text-field
                outlined
                v-model.number="form.days_until_due"
                number
              ></v-text-field>
            </div>
            <div class="d-flex action-btn mt-15 mb-4">
              <v-btn color="primary" text @click="step = step - 1" outlined
                >PREVIOUS</v-btn
              >
              <v-btn
                color="primary"
                medium
                @click="step = step + 1"
                >NEXT</v-btn
              >
            </div>
          </v-stepper-content>

          <v-stepper-content step="5">
            <v-checkbox
              color="#013138"
              v-model="addVoucher"
              label="Add Voucher"
            ></v-checkbox>
            <div v-if="addVoucher">
              <create-voucher @cancel="stepBack()" @success="createdVoucher">
              </create-voucher>
            </div>

            <div class="d-flex action-btn mt-15 mb-4" v-if="!addVoucher">
              <v-btn color="primary" text @click="step = step - 1" outlined
                >PREVIOUS</v-btn
              >
              <v-btn
                color="primary"
                medium
                :loading="loading"
                @click="step = step + 1"
                >NEXT</v-btn
              >
            </div>
          </v-stepper-content>
          <v-stepper-content step="6">
            <div class="form-wrap" v-if="allCompleted">
              <div class="form-title">Business</div>
              <div class="form-descr">{{ business.display_name }}</div>
              <div class="form-title mt-4">Product plan</div>
              <div class="form-descr">{{ selectedPlan.nickname }}</div>
              <div class="form-descr">{{ selectedPlan.interval }}</div>
              <div class="form-descr">
                {{ selectedPlan.amount / 100 }} {{ selectedPlan.currency }}
              </div>

              <div v-if="form.trial_period_days" class="form-title mt-4">
                Trial
                <div class="form-descr">{{ form.trial_period_days }}</div>
              </div>

              <div class="form-title mt-4">Tax</div>
              <div class="form-descr">
                {{
                  tax
                    ? `${tax[0].display_name} (${tax[0].percentage} %)`
                    : "No tax"
                }}
              </div>
              <div
                class="form-title mt-4"
                v-if="voucher.name && voucher.duration"
              >
                Voucher
                <div class="form-descr">{{ voucher.name }}</div>
                <div class="form-descr">{{ voucher.duration }}</div>
              </div>
            </div>
            <div class="d-flex action-btn mt-15 mb-4">
              <v-btn color="primary" text @click="step = step - 1" outlined
                >PREVIOUS</v-btn
              >
              <v-btn
                color="primary"
                :disabled="!selectedPlan"
                medium
                :loading="loading"
                @click="save()"
                >CONFIRM</v-btn
              >
            </div>
          </v-stepper-content>
        </v-stepper>
        <div class="create-footer">
          <span
            v-for="n in 6"
            :key="n"
            :class="{ 'is-active': n === step }"
          ></span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
import StripeProductPlanSelect from "../selects/StripeProductPlanSelect";
import CreateVoucher from "../dialogs/CreateVoucher";

import { mapActions } from "vuex";

export default {
  components: {
    BusinessAutocomplete,
    StripeProductPlanSelect,
    CreateVoucher,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    step: 1,
    trial: false,
    tax: null,
    amount: null,
    addVoucher: false,
    selectedPlan: null,
    business: null,
    voucher: {
      duration: "once",
    },
    voucherId: null,
    redeemBy: null,
    durations: [
      {
        name: "Once",
        value: "once",
      },
      {
        name: "Forever",
        value: "forever",
      },
      {
        name: "Multiple months",
        value: "multiple",
      },
    ],
    redemptionLimitsItems: [
      {
        text: "Add an expiration date",
        value: "expiration_date",
      },
      {
        text: "Limit  the total number of times this voucher can be redeemed",
        value: "max_redemptions",
      },
    ],
    redemptionLimits: null,
    loading: false,
    startDate: null,
    endDate: null,
    form: {},
    taxRates: [],
    type: "percent_off",
    confirmAddingDays: false,
  }),
  watch: {
    business: {
      handler: function(val) {
        this.form.collection_method = !val.user.card_last_four
          ? "send_invoice"
          : "charge_automatically";
      },
      deep: true,
    },
  },
  computed: {
    allCompleted() {
      return this.selectedPlan && this.business;
    },
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("core", ["createSubsctiption"]),
    ...mapActions("moderator", ["getTaxRates"]),
    taxText(item) {
      return `${item.display_name} (${item.percentage} %)`;
    },
    stepBack() {
      return (this.step = this.step - 1);
    },
    createdVoucher(value) {
      this.voucher = value;
      this.voucherId = value.id;
      this.step = this.step + 1;
    },
    save() {
      this.loading = true;
      let params = {
        ...this.form,
        plan: this.selectedPlan.id,
        product: "standard",
        business_uuid: this.business.uuid,
      };
      if (this.voucherId) params.coupon = this.voucherId;
      this.createSubsctiption(params).then(() => {
        this.$emit("success");
        this.setSnackSuccess("Gemt");
        this.loading = false;
      }),
        () => {
          this.loading = false;
          this.setSnackError("Fejl");
        };
    },
    getTaxList() {
      this.getTaxRates().then((response) => {
        this.taxRates = response;
      });
    },
  },
  created() {
    this.getTaxList();
  },
};
</script>

<style scoped lang="scss">
.create-footer {
  height: 45px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  margin-bottom: 20px;
  span {
    display: block;
    height: 12px;
    width: 12px;
    margin: 5px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    &.is-active {
      background: rgba(0, 0, 0, 0.4);
      background: var(--v-primary-base) !important;
    }
  }
}

.create-wrap {
  width: 50%;
  padding-top: 100px;
  margin: 0 auto;
  .v-stepper__content {
    padding: 0;
  }
  .v-stepper,
  .v-stepper__header {
    box-shadow: none;
  }
  .title {
    font-size: 28px !important;
  }
  .description {
    font-size: 14px;
    color: var(--v-greytxt-base);
  }
  .small-title {
    font-size: 16px;
    color: var(--v-primary-base);
    font-weight: 500;
  }
}
.action-btn {
  justify-content: space-between;
}
.input-form {
  .v-input {
    margin-top: 10px;
    width: 30%;
  }
}
.form-wrap {
  .form-title {
    font-size: 14px;
    color: var(--v-greytxt-base);
  }
}
.form-descr {
  color: var(--v-primary-base);
}
</style>
