var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":650,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"advanced-btn",attrs:{"icon":""}},'div',attrs,false),on),[_c('span',[_vm._v(" Advanced filter ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-angle-down")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('div',{staticClass:"advanced-filters-title"},[_vm._v(" Advanced filter ")]),_c('div',{staticClass:"date-range-template advanced-filters"},[_c('div',{staticClass:"filter-title"},[_vm._v(" Created Subscription ")]),_c('date-range-picker',{attrs:{"label":false,"ranges":_vm.ranges,"opens":"right","value":{
          start_date: _vm.filtersPicker.created_at_range_start,
          end_date: _vm.filtersPicker.created_at_range_end,
        }},on:{"changePeriod":function($event){return _vm.changePeriod('created_at', $event)}}})],1),_c('div',{staticClass:"date-range-template advanced-filters"},[_c('div',{staticClass:"filter-title"},[_vm._v(" Cancelled subscription ")]),_c('date-range-picker',{attrs:{"label":false,"opens":"right","ranges":_vm.ranges,"value":{
          start_date: _vm.filtersPicker.canceled_at_range_start,
          end_date: _vm.filtersPicker.canceled_at_range_end,
        }},on:{"changePeriod":function($event){return _vm.changePeriod('canceled_at', $event)}}})],1),_c('div',{staticClass:"date-range-template advanced-filters"},[_c('div',{staticClass:"filter-title"},[_vm._v(" Subscription end date ")]),_c('date-range-picker',{attrs:{"label":false,"ranges":_vm.ranges,"opens":"right","value":{
          start_date: _vm.filtersPicker.ends_at_range_start,
          end_date: _vm.filtersPicker.ends_at_range_end,
        }},on:{"changePeriod":function($event){return _vm.changePeriod('ends_at', $event)}}})],1),_c('div',{staticClass:"date-range-template advanced-filters"},[_c('div',{staticClass:"filter-title"},[_vm._v(" Next payment period ")]),_c('date-range-picker',{attrs:{"label":false,"ranges":_vm.ranges,"opens":"right","value":{
          start_date: _vm.filtersPicker.next_period_at_range_start,
          end_date: _vm.filtersPicker.next_period_at_range_end,
        }},on:{"changePeriod":function($event){return _vm.changePeriod('next_period_at', $event)}}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Clear ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveFilters}},[_vm._v(" Filter ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }