<template>
  <div class="subscriptions-table">
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="pagination"
      :loading="loading"
      :search="search"
      hide-default-footer
      disable-sort
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>
                <router-link
                :to="{ name: 'subscription', params: { id: item.stripe_id } }"
              >
                {{ item.user.business.display_name }}
              </router-link>
         
            </td>
            <td> 
            <v-chip
                :color="getColor(item.stripe_status)"
                class="subscription-chip"
                >
            {{ item.stripe_status }}
            </v-chip>
            </td>
            <td>{{ item.product  }}</td>
            <td>{{ item.interval }}</td>

            <td>{{ item.monthly_recurring_revenue  | price_format }}</td>
            <td class="word-no-wrap">
              {{ item.created_at | utc_to_local_datetime }}
            </td>
            <td class="column-width">{{ item.cancel_reason_text }}</td>

          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currPage"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
  </div>
</template>

<script>
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import price_format from "@/helpers/filters/price_format";

export default {
  filters: {
    utc_to_local_datetime,
    price_format
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    page: {
      type: Number
    },
    total: {
      type: Number
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: () => ({
    currPage: 1,
    headers: [
      { text: "BUSINESS", value: "company" },
      { text: "STATUS", value: "stripe_status" },
      { text: "PRODUCT", value: "product" },
      { text: "PERIOD", value: "interval" },
      { text: "MRR", value: "monthly_recurring_revenue" },

      { text: "CREATED", value: "created_at" },
      { text: "CANCELLATION REASON", value: "cancel_reason" }
    ],
    pagination: {}
  }),
  watch: {
    items() {
      this.currPage = this.page;
    }
  },
  methods: {
    getColor (item) {
      if (item === "canceled") return '#F9E0E0'
      else if (item === "active") return '#BEDBBE'
      else return ""
    },
    handlePageChange(value) {
      this.$emit("changePage", value);
    }
  },
  
};
</script>
<style lang="scss">
.v-data-table__progress {
  th {
    padding: 0 !important;
  }
}
tbody{
  td:last-child{
    max-width: 200px;
  }
}
</style>
