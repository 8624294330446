<template>
  <v-card>
    <v-card-title>
      <div>
        <div class="headline">Subscriptions</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="createSubscriptionDialog = true">
        create a subscription
      </v-btn>
    </v-card-title>
    <v-spacer></v-spacer>

    <v-card-text>
      <subscription-filters
        @save="filters = $event"
        :filters="filters"
      ></subscription-filters>
    </v-card-text>

    <v-card-text>
      <subscriptions-data-table
        :items="subscriptions"
        :loading="loading"
        :page="page"
        :total="total"
        @changePage="getSubscriptions($event)"
      ></subscriptions-data-table>
    </v-card-text>
    <create-subscription
      v-if="createSubscriptionDialog"
      v-model="createSubscriptionDialog"
      @success="createSubscriptionDialog = false;
      getSubscriptions()"
    ></create-subscription>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import SubscriptionsDataTable from "../data-tables/SubscriptionsDataTable";
import SubscriptionFilters from "@/components/common/filters/SubscriptionFilters";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import CreateSubscription from "../dialogs/CreateSubscription";
import _ from "lodash";

export default {
  mixins: [search_filters_url],

  data: () => ({
    subscriptions: [],
    influencers: [],
    filters: {},
    loading: true,
    limit: 15,
    page: 1,
    total: null,
    createSubscriptionDialog: false,
  }),
  components: {
    SubscriptionsDataTable,
    SubscriptionFilters,
    CreateSubscription,
  },
  watch: {
    filters: {
      handler: _.debounce(function() {
        this.getSubscriptions();
      }, 500)
    },
  },
  methods: {
    ...mapActions("moderator", ["getModeratorSubscriptions"]),

    getSubscriptions(page = 1) {
      this.loading = true;
      this.page = page;
      this.subscriptions = [];
      this.filters.page =  this.page;
      this.filters.limit =  this.limit

      const filters = this.cloneDeep(this.filters);
      if (filters["stripe_plan"] === "all") {
        delete filters["stripe_plan"];
      }
      this.setUrlSearchFilters(this.filters);
      this.getModeratorSubscriptions(filters)
        .then(subscriptions => {
         this.subscriptions = subscriptions.response;
        this.total = subscriptions.lastPage;
        this.loading = false;
        })
        .catch(() => {
          this.setSnackError("Fejl");
        });
    
    
    },
  },
  created() {
     if (Object.keys(this.$route.query).length > 0) {
      const filters = this.getUrlSearchFilters()
      this.filters = this.cloneDeep(filters);
    }

  },
};
</script>
