<template>
  <div class="filters-wrap">
    <div class="filter-selects-wrap">
      <business-autocomplete
        v-model="filtersTmp.business_uuid"
        :return-object="false"
        class="mr-10 filter-select"
        @change="saveFilters"
        :avatar="false"
      ></business-autocomplete>
      <v-select
        v-model="filtersTmp.product"
        :items="productSelect"
        class="mr-10 filter-select width"
        @change="saveFilters"
        clearable
        label="Product"
      ></v-select>
      <v-select
        v-model="filtersTmp.stripe_plan"
        @change="saveFilters"
        :items="planItems"
        clearable
        class="mr-10 filter-select width"
        label="Interval"
      ></v-select>
      <v-select
        v-model="filtersTmp.status"
        @change="saveFilters"
        :items="statusItems"
        clearable
        class="mr-10 filter-select width"
        label="Status"
      ></v-select>
      <v-select
        v-model="filtersTmp.cancel_reason"
        :items="cancelReasons"
        clearable
        @change="saveFilters"
        multiple
        class="mr-10 filter-select width"
        label="Cancellation"
      > 
      <template v-slot:selection="{ item, index }">
           <span v-if="index === 0">{{  item.text.substring(0,8)+".."}}</span>
          <span
            v-if="index === 1"
            class="grey--text caption"
          >
            (+{{ filtersTmp.cancel_reason.length - 1 }} others)
          </span>
        </template>
        </v-select>
      <advanced-subscription-filters
      
        @save="saveAdvancedFilters"
      
       :filters="filters" />
    </div>
  </div>
</template>
<script>
import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
import AdvancedSubscriptionFilters from "@/components/common/filters/AdvancedSubscriptionFilters";

export default {
  components: {
    BusinessAutocomplete,
    AdvancedSubscriptionFilters
 },
  props: {
    filters: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    filtersTmp: {},
    productSelect: [
      {
        text: "All products",
        value: "all",
      },
      {
        text: "Managed",
        value: "managed",
      },
      {
        text: "Self-service",
        value: "self-service",
      },
    ],
    planItems: [
      {
        text: "All plans",
        value: "all",
      },
      {
        text: "1 months",
        value: "standard_monthly",
      },
      {
        text: "3 months",
        value: "standard_quarterly",
      },

      {
        text: "6 months",
        value: "standard_annually",
      },
      {
        text: "12 months",
        value: "standard_biannually",
      },
    ],
    statusItems: [
     
      {
        text: "Active",
        value: "ACTIVE",
      },
      {
        text: "Unpaid",
        value: "UNPAID",
      },
      {
        text: "Incomplete",
        value: "INCOMPLETE",
      },

      {
        text: "Trial",
        value: "TRIALING",
      },
      {
        text: "Canceled",
        value: "CANCELED",
      },
      {
        text: "Past due",
        value: "PAST_DUE",
      },
      {
        text: "Incomplete Expired",
        value: "INCOMPLETE_EXPIRED",
      }
    ],
    cancelReasons: [
      { value: "lacking_time", text: "Har ikke tiden til det" },
      {
        value: "pausing_influencer_marketing",
        text: "Pausing influencer marketing",
      },
      {
        value: "influencers_not_active",
        text: "Influencerne not active",
      },
      {
        value: "trouble_finding_influencers",
        text: "Trouble finding influencers",
      },
      {
        value: "our_product_doesnt_fit",
        text: "Our product doesn't fit",
      },
      {
        value: "platform_hard_to_understand",
        text: "The platform is hard to understand",
      },
      {
        value: "expected_more_service",
        text: "Expected more service and help",
      },
      {
        value: "too_many_products",
        text: "Had to send too many products to influencers",
      },
      {
        value: "not_enough_conversions",
        text: "Not enough conversions",
      },
      {
        value: "other",
        text: "Other",
      },
    ],
  }),
  watch: {
    filtersTmp: {
      immediate: true,
      deep: true,
      handler() {
        this.saveFilters();
      },
    },
  },
  methods: {
    saveAdvancedFilters(event){
       this.filtersTmp = {
         ...this.filtersTmp,
         ...event
       }
    },
    saveFilters() {
      this.$emit("save", this.cloneDeep(this.filtersTmp));
    },
    changePeriod(event) {
      this.filtersTmp.created_at_range_start = event.start_date;
      this.filtersTmp.created_at_range_end = event.end_date;
      this.$emit("save", this.cloneDeep(this.filtersTmp));

    },
  },
  created() {
    this.filtersTmp = this.cloneDeep(this.filters);
  },
};
</script>
<style lang="scss">
.filters-wrap {
  display: flex;
  align-items: center;
}

.filter-selects-wrap {
  display: flex;
  align-items: center;
  .v-icon.v-icon{
    font-size: 18px;
  }
  .v-autocomplete{
    .v-select__selections{
      max-width: 100px;
      height: 32px;
    }
    .v-list-item__content{
      padding: 0;
    }
    .v-list-item__subtitle {
      display: none;
    }
    .v-list-item{
      min-height: auto;
    }
    .v-list-item__title{
      font-size: 14px;
    }
  }
  .v-input__slot {
    &::before {
      display: none;
    }
    .v-input__append-inner {
      margin-top: 4px !important;
    }
    .v-label,
    .v-select__selection {
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 500;
    }
    .v-icon.v-icon {
      color: #4f4f4f;
      font-weight: 500;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    }
}
.filter-select {
  max-width: 250px;
  &.width .v-input__control {
    max-width: 100px;
    width: auto;
    display: flex;
    align-items: center;
  }
  .v-input__control {
    height: 53px;
  }
  &:hover {
    .v-label,
    .v-select__selection {
      color: #828282;
    }
    .v-icon.v-icon {
      color: #828282;
    }
  }
}
</style>
